import React, { useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'


export default function Dashboard() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {club} = useSelector(state=>state.clubs)

    return (
        <Container className='p-5'> 
            <Row>
                <Col>
                    <div className='sd-label-dashboard'>{t('club.dashboard.welcome')}</div>
                    <div className="sd-label-userdashboard">{club?.name}</div>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <h3>{t('club.dashboard.subwelcome')}</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={3} className='my-3'>
                    <Card>
                        <Link to={`/${t("url.club.club")}/${t("url.club.payments")}`} className='btn btn-dashboard'>
                            <h3 className='noBold'>{t('club.dashboard.payments')}</h3>
                        </Link>
                    </Card>
                </Col>
            </Row>
            <Row>
            {
                club?.activitybooking
                ?
                    <>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.inscriptions")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.inscriptions')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.groups")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.groups')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.activities")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.activities')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.categories")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.categories')}</h3>
                                </Link>
                            </Card>
                        </Col>
                    </>
                :
                    <></>
            }
            </Row>
            <Row>
            {
                club?.renting
                ?
                    <>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.sites")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.sites')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.courts")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.courts')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.courtsrents")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.courtsrents')}</h3>
                                </Link>
                            </Card>
                        </Col>
                    </>
                :
                    <></>
            }
            </Row>
            <Row>
            {
                club?.ticketing
                ?
                    <>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.matches")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.matches')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.passes")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.passes')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.tickets")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.tickets')}</h3>
                                </Link>
                            </Card>
                        </Col>
                    </>
                :
                    <></>
            }
            </Row>
        </Container>
    )
}