import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import Loader from '../../../../components/design/Loader/Loader'
import { getTickets, getTicketsByClub } from '../../../../actions/ticketing/ticketsActions'
import TicketSummary from '../../../../components/ticketing/TicketSummary'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'
import TicketingSubMenu from '../../../../components/menus/TicketingSubMenu'
import Swal from 'sweetalert2'
import WindowTicket from '../../../../components/ticketing/WindowTicket'
import { Link } from 'react-router-dom'
import { sendEmailDownloadEntry } from '../../../../actions/downloads/downloadsActions'
import QRCode from 'qrcode';

export default function Tickets({usertype, isAdmin = false}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {tickets, loading, result} = useSelector(state=>state.ticket)
    const {resultdownload} = useSelector(state=>state.downloads)
    const {club} = useSelector(state=>state.clubs)

    const [ticketSelected, setTicketSelected] = useState(null)
    const [formType, setFormType] = useState(null)

    const [addWindowTicket, setAddWindowTicket] = useState(false)

    const handleSendEmail = (e, ticketId) => {
        e.preventDefault()

        dispatch(sendEmailDownloadEntry(ticketId))
    }

    const QRCodeImage = ({ identifier }) => {
        const [qrImageUrl, setQrImageUrl] = useState(null);

        useEffect(() => {
          const generateQRCode = async () => {
            try {
              const qrImageUrl = await QRCode.toDataURL(identifier);
              setQrImageUrl(qrImageUrl);
            } catch (error) {
              console.error(error);
            }
          };
          generateQRCode();
        }, [identifier]);

        return qrImageUrl ? <img src={qrImageUrl} alt="qr code" style={{width: '200px', height: '200px'}}/> : null;
    };

	const columnsTickets = [
        {
            name: t('tickets.identifier'),
            cell: ticket =>
                ticket.identifier,
            selector: (ticket) => ticket.identifier,
            sortable: true,
        },
        // {
        //     name: 'QR CODIGOOOOOO',
        //     width: "320px",
        //     cell: pass =>
        //         <div style={{width: '100%', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        //             <QRCodeImage identifier={pass?.identifier} style={{width: '100%', height: '100%'}}/>
        //         </div>,
        //     selector: (pass) => pass.season.name,
        //     sortable: true,
        // },
        {
            name: t('tickets.match'),
            width: "200px",
            cell: ticket =>
                <div>
                    <div className='fw-bold'>{`${ticket.match.host} - ${ticket.match.visitor}`}</div>
                    <div><small>{`${new Date(ticket.match.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${ticket.match.hour}`}</small></div>
                </div>,
            selector: (ticket) => `${ticket.match.host} - ${ticket.match.visitor} ${new Date(ticket.match.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${ticket.match.hour}`,
            sortable: true,
        },{
            name: t('tickets.sector'),
            cell: ticket =>
                ticket.sector.name,
            selector: (ticket) => ticket.sector.name,
            sortable: true,
        },{
            name: t('tickets.status'),
            center: 'true',
            cell: ticket =>
                ticket.status === 0 ? t('tickets.pending') :
                ticket.status === 1 ? t('tickets.active') :
                ticket.status === 2 ? t('tickets.canceled') :
                t('tickets.unknown'),
            selector: (ticket) =>
                ticket.status === 0 ? t('tickets.pending') :
                ticket.status === 1 ? t('tickets.active') :
                ticket.status === 2 ? t('tickets.canceled') :
                t('tickets.unknown'),
            sortable: true,
        },{
            name: t('tickets.price'),
            center: 'true',
            width: "100px",
            cell: ticket => (
                    ticket.price !== null && ticket.price !== undefined
                    ?
                        <div>
                            {ticket.price} €
                        </div>
                    :
                        <></>
            ),
            selector: (ticket) => ticket.price,
            sortable: true,
        },{
            name: t('tickets.client'),
            cell: ticket => (
                <div>
                    <span>{`${ticket.personData?.firstname} ${ticket.personData?.lastname || ''}`}</span>
                    {
                        ticket.pass
                        ?
                            <>
                                <br />
                                <small>{ticket.pass.passType?.name}</small>
                            </>
                        :
                            <></>
                    }
                </div>
            ),
            selector: (ticket) => `${ticket.personData?.firstname} ${ticket.personData?.lastname || ''} ${ticket.pass?.passType?.name || ''}`,
            sortable: true,
        },
        {
            name: t('global.actions'),
            center: 'true',
            width: '150px',
            cell: ticket =>
                <div className='d-flex w-100 justify-content-center align-items-center'>
                    <div
                        onClick={() => {
                            setTicketSelected(ticket)
                            setFormType("view")
                        }}
                        className='table-link me-2'>
                        <OverlayTrigger placement="top"
                            overlay={<Tooltip id="tooltip">{t('tickets.seeTicket')}</Tooltip>}
                        >
                            <img className="cursor" src="/images/eyeopen.svg" alt="eyes" height={16}/>
                        </OverlayTrigger>
                    </div>
                    <div
                        onClick={(e) => {
                            handleSendEmail(e, ticket._id)
                        }}
                        className='table-link me-2'>
                        <OverlayTrigger placement="top"
                            overlay={<Tooltip id="tooltip">{t('tickets.sendemail')}</Tooltip>}
                        >
                            {/* <img src="/images/envelope.svg" alt="Email" className='me-2 my-2' /> */}
                            <i className='far fa-envelope'></i>
                        </OverlayTrigger>
                    </div>
                    <div className='table-link me-2'>
                        <a className='table-link' href={`/${t("url.marketplace.downloadticket")}/${ticket._id}/${ticket.identifier}`} rel='noopener noreferrer' target='_blank'>
                            <OverlayTrigger placement="top"
                                overlay={<Tooltip id="tooltip">{t('tickets.goToMarketplace')}</Tooltip>}
                            >
                                {/* <img className="cursor" src="/images/arrow-right.svg" alt="arrow" height={16}/> */}
                                <i className='fa-solid fa-download'></i>
                            </OverlayTrigger>
                        </a>
                    </div>
                    <div
                        onClick={() => {
                            setTicketSelected(ticket)
                            setFormType("edit")
                        }}
                        className='table-link'>
                        <OverlayTrigger placement="top"
                            overlay={<Tooltip id="tooltip">{t('tickets.editTicket')}</Tooltip>}
                        >
                            <img className="cursor" src="/images/pencil.svg" alt="pencil" height={16}/>
                        </OverlayTrigger>
                    </div>
                </div>,
            sortable: false,
        }
	]

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (isAdmin) {
            dispatch(getTickets())
        } else {
            if(club){
                dispatch(getTicketsByClub(club._id))
            }
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth])

    useEffect(() => {
        if (result) {
            switch (result) {
                case 'changeStatusSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('tickets.changeStatusSuccess')
                    })
                    setTicketSelected(null)
                    setFormType(null)
                    dispatch(getTicketsByClub(club._id))
                    dispatch({ type: "TICKET_CHANGE_STATUS_RESET" })
                    break;
                default:
                    break;
            }
        }
    }, [result, club, dispatch, t])

    useEffect(() => {
        if (resultdownload) {
            switch (resultdownload) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('passes.emailsendsuccess')
                    })
                    break;
                case 'notentryexist':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('passes.notentryexist')
                    })
                    break;
                case 'notemailexist':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('passes.notemailexist')
                    })
                    break;
                default:
                    break;
            }
            dispatch({type: 'DOWNLOAD_RESET_RESULT'})
        }
    }, [resultdownload, t, dispatch])

    return (
        <Container className='cuerpo'>
            {
                isAdmin
                ?
                    <Row>
                        <Col className='d-flex align-items-center'>
                            <h1>{t('menus.ticketing.tickets')}</h1>
                        </Col>
                    </Row>
                :
                <>
                    <ClubBreadCrumbs usertype={usertype} club={club} />
                    <Row className="my-3">
                        <Col>
                            <TicketingSubMenu usertype={usertype} active="tickets"/>
                        </Col>
                    </Row>
                </>
            }
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className='d-flex justify-content-between align-items-end'>
                                <h2>{t('tickets.ticketsList')}</h2>
                                <div>
                                    <Button variant="secondary" type="button" className='me-2' onClick={() => setAddWindowTicket(true)}>
                                        <i className='fas fa-plus'/> {t('tickets.addWindowTicket')}
                                    </Button>
                                </div>
                            </div>
                            <hr className='mb-4'/>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            loading
                                                ?
                                                    <Loader/>
                                                :
                                                    <>
                                                        {
                                                            tickets?.length > 0 ?
                                                                <CustomDataTable
                                                                    columns={columnsTickets}
                                                                    data={tickets}
                                                                    exportable={false}
                                                                    printable={false}
                                                                />
                                                            :
                                                                <div className='contenedor-vacio text-center'>
                                                                    <h4>{t('tickets.noTickets')}</h4>
                                                                    {t('tickets.thereIsNoTicketsPhrase')}
                                                                </div>
                                                        }
                                                    </>
                                        }
                                        
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <TicketSummary ticketSelected={ticketSelected} closeModal={() => setTicketSelected(null)} formType={formType} usertype={usertype}/>
            <WindowTicket userType="club" closeModal={() => {setAddWindowTicket(false)}} show={addWindowTicket} club={club}/>
        </Container>
    )
}