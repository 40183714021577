
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector  } from "react-redux"
import { getReservationPublicByCourtAndDate } from '../../../actions/sites/reservationsActions'
import { es } from 'date-fns/locale'
import useAuth from '../../../context/auth/useAuth'
import { useTranslation } from 'react-i18next'
import { addDays } from 'date-fns';
import { substituteCartItem } from '../../../actions/payments/cartActions'
import { getClient } from '../../../actions/profiles/clientsActions'
import Swal from 'sweetalert2'

export default function Reserve({ court, club }) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const {clientauth} = useAuth()

    const {reservations} = useSelector(state=>state.reservations)
    const {client} = useSelector(state => state.clients)
    const {status, items} = useSelector(state => state.cart)
    const {commission} = useSelector(state=>state.commissions)

    // Estados
    // Asigna el dia de hoy con la hora en formato 00:00:00 para que sea capaz de obtener el dia correcto con GMT+2
    const [selectedDate, setSelectedDate] = useState(() => {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        return date;
    })
    const [durations, setDurations] = useState([])
    const [selectedDuration, setSelectedDuration] = useState(null)
    const [availableHours, setAvailableHours] = useState([])
    const [groupedHours, setGroupedHours] = useState([])
    const [reservationsLoaded, setReservationsLoaded] = useState(false)
    const [minDurationAll, setMinDurationAll] = useState(0)

    const [reservationsValues, setReservationsValues] = useState([])

    const [selectedValues, setSelectedValues] = useState([])
    const [personData, setPersonData] = useState({
        firstname: null,
        lastname: null,
        email: null,
        phone: null
    })

    const [isDateBlocked, setIsDateBlocked] = useState(false)

    // Efecto para calcular las duraciones permitidas cuando se selecciona la fecha
    useEffect(() => {
        if (selectedDate && court) {
            setReservationsLoaded(false)

            const isBlocked = court?.datesBlocked?.some(blockedDate => {
                const startDate = new Date(blockedDate.startDate);
                const endDate = new Date(blockedDate.endDate);
                const selected = new Date(selectedDate);
                return selected >= startDate && selected <= endDate;
            })
    
            setIsDateBlocked(isBlocked)

            const minDuration = court.timeSlot * court.minSlots
            const maxDuration = court.timeSlot * court.maxSlots
            const durations = [];
            for (let duration = minDuration; duration <= maxDuration; duration += court.timeSlot) {
                durations.push(duration);
            }

            setDurations([...new Set(durations)]); // Elimina duplicados
            setSelectedDuration(durations[0]); // Selecciona la duración mínima por defecto
            setMinDurationAll(minDuration)

            const madridTimeZone = new Date(selectedDate).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric',month: '2-digit', day: '2-digit'})

            dispatch(getReservationPublicByCourtAndDate(court?._id, madridTimeZone))
        }
    }, [selectedDate, court, clientauth, dispatch])

    // Efecto para verificar si las reservas han sido cargadas
    useEffect(() => {
        if (reservations) {
            setReservationsLoaded(true)
        }
    }, [reservations])

    // Efecto para calcular las horas disponibles cuando se selecciona una duración
    useEffect(() => {
        // Verifica si se han seleccionado la duración, fecha, pista y si se han cargado las reservas
        if (selectedDuration && selectedDate && court && reservationsLoaded) {
            // Crea un objeto Date a partir de la fecha seleccionada
            const currentDate = new Date(selectedDate)
            // Obtiene el día de la semana en formato de texto
            const dayOfWeek = currentDate.toLocaleString('en-US', { weekday: 'long' }).toLowerCase()

            // Filtra los horarios relevantes según la fecha y los días de la semana
            const relevantSchedules = court.schedules.filter(schedule => {
                const startDate = new Date(schedule.startDate)
                const endDate = new Date(schedule.endDate)
                return currentDate >= startDate &&
                    currentDate <= endDate &&
                    schedule.weekDays.includes(dayOfWeek)
            })

            // Usamos un objeto para consolidar horas y precios
            let consolidatedHours = {}
            let hoursAllSlots = []

            // Recorre cada horario relevante y consolida las horas y precios
            relevantSchedules.forEach(schedule => {
                const startHour = new Date(`${selectedDate.toDateString()} ${schedule.startHour}`)
                const endHour = new Date(`${selectedDate.toDateString()} ${schedule.endHour}`)

                for (let time = startHour; time <= endHour; time.setMinutes(time.getMinutes() + selectedDuration)) {
                    const timeKey = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

                    // Sobrescribimos la hora con el precio del horario más reciente
                    consolidatedHours[timeKey] = schedule.price
                    if(time < endHour){
                        hoursAllSlots.push(timeKey)
                    }
                }
            })

            // Convertimos las horas y precios consolidados en arrays
            let hours = Object.keys(consolidatedHours)
            let prices = Object.values(consolidatedHours)

            // Filtra horas ocupadas
            let filteredPrices = []
            const filteredHours = hours.filter((hour, index) => {
                const [hourString, minuteString] = hour.split(':')
                const hourDate = new Date(selectedDate)
                hourDate.setHours(parseInt(hourString), parseInt(minuteString))

                // Verifica si la hora está dentro de algún intervalo de reserva
                const isAvailable = !reservations.some(reservation => {
                    const reservationStart = new Date(`${selectedDate.toDateString()} ${reservation.startHour}`)
                    const reservationEnd = new Date(`${selectedDate.toDateString()} ${reservation.endHour}`)
                    return hourDate >= reservationStart && hourDate < reservationEnd
                })

                if (isAvailable) {
                    // Si la hora está disponible, agregamos el precio correspondiente al array filtrado
                    filteredPrices.push(prices[index])
                }

                return isAvailable // Mantener la hora si está disponible
            })

            // Filtrar horas que cumplen con la duración máxima y que están disponibles
            const validHours = filteredHours.filter(hour => {
                const [hourString, minuteString] = hour.split(':')
                const hourDate = new Date(selectedDate)
                hourDate.setHours(parseInt(hourString), parseInt(minuteString))

                // Comprobar si se puede reservar a partir de esta hora
                const endReservationTime = new Date(hourDate)
                endReservationTime.setMinutes(endReservationTime.getMinutes() + selectedDuration) // Usa la duración seleccionada

                // Comprobar si el tiempo de finalización excede el horario permitido
                return relevantSchedules.some(schedule => {
                    const scheduleEndHour = new Date(`${selectedDate.toDateString()} ${schedule.endHour}`)
                    return endReservationTime <= scheduleEndHour
                }) && filteredHours.includes(hour)
            })

            const hoursAllSlotsFiltered = hoursAllSlots.filter(hour => {
                return filteredHours.includes(hour)
            })

            // validHours es el array de horas NO RESERVADAS y existentes de horarios
            // finalValidHours es el array de horas que aparecen como ALQUILABLES tras su comprobación de validHours con respecto a mínimos
            let finalValidHours = []
            let finalSlotsHoursInMinutes = []
            let finalValidHoursInMinutes = []
            let finalAllHoursInMinutes = []
            let arrayReservationsValues = []
            // Convertir las horas válidas a minutos
            finalSlotsHoursInMinutes = hoursAllSlotsFiltered.map(hour => {
                let [hours, minutes] = hour.split(":").map(Number)
                return hours * 60 + minutes
            })
            finalValidHoursInMinutes = validHours.map(hour => {
                let [hours, minutes] = hour.split(":").map(Number)
                return hours * 60 + minutes
            })
            finalAllHoursInMinutes = filteredHours.map(hour => {
                let [hours, minutes] = hour.split(":").map(Number)
                return hours * 60 + minutes
            })
            // console.log('finalValidHoursInMinutes', finalValidHoursInMinutes)
            // console.log('validHours', validHours)
            // console.log('finalAllHoursInMinutes', finalAllHoursInMinutes)
            // console.log('filteredHours', filteredHours)
            // console.log('finalSlotsHoursInMinutes', finalSlotsHoursInMinutes)
            // console.log('hoursAllSlotsFiltered', hoursAllSlotsFiltered)
    
            // Duración seleccionada y tramo mínimo
            const timeSelectedToRent  = selectedDuration

            finalValidHoursInMinutes.forEach((hour, index) => {
                // console.log(hour)
                // console.log('hora: ', validHours[index]);
                let isAvailableForJumps = true

                // Calcular los saltos necesarios para la duración seleccionada
                const necessaryJumps = Math.floor(timeSelectedToRent  / selectedDuration)
    
                // Verificamos si los tramos de la duración seleccionada están disponibles
                for (let jumps = 0; jumps < necessaryJumps; jumps++) {
                    const nextHour = hour + (jumps * selectedDuration)
    
                    // Comprobar si cada tramo necesario está disponible
                    if (!finalSlotsHoursInMinutes.includes(nextHour)) {
                        isAvailableForJumps = false
                        break // Salir si un tramo no está disponible
                    }
                }

                // Se puede reservar algo hacia adelante?
                if (isAvailableForJumps) {
                    let forwardArrayAux = []
                    const necessaryForwardJumps = (necessaryJumps + Math.floor(minDurationAll / selectedDuration))

                    // Verificamos si los tramos de la duración seleccionada están disponibles
                    for (let jumps = necessaryJumps; jumps < necessaryForwardJumps; jumps++) {
                        forwardArrayAux.push(hour + (jumps * selectedDuration))
                    }

                    const containsAll = forwardArrayAux.every(hourToCheck => finalSlotsHoursInMinutes.includes(hourToCheck))
                    const containsAtLeastOne = forwardArrayAux.some(hourToCheck => finalSlotsHoursInMinutes.includes(hourToCheck))
                    const containsNone = !containsAtLeastOne

                    // console.log(forwardArrayAux)
                    // console.log('forwardArrayAux - finalSlotsHoursInMinutes', forwardArrayAux+' - '+finalSlotsHoursInMinutes)
                    // console.log('contieneTodos - containsNone', containsAll+' - '+containsNone)

                    if (!containsAll && !containsNone) {
                        isAvailableForJumps = false // No se puede reservar si no hay suficientes tramos disponibles
                    }
                }
    
                // Ahora, comprobamos hacia atrás
                if (isAvailableForJumps) {
                    let backwardArrayAux = []
                    const necessaryBackwardJumps = Math.floor(minDurationAll / selectedDuration)

                    // Verificamos si los tramos de la duración seleccionada están disponibles hacia atrás
                    for (let jumps = 1; jumps <= necessaryBackwardJumps; jumps++) {
                        const previousHour = hour - (jumps * selectedDuration)
                        backwardArrayAux.push(previousHour)
                    }

                    const containsAllBackward = backwardArrayAux.every(hourToCheck => finalSlotsHoursInMinutes.includes(hourToCheck))
                    const containsAtLeastOneBackward = backwardArrayAux.some(hourToCheck => finalSlotsHoursInMinutes.includes(hourToCheck))
                    const containsNoneBackward = !containsAtLeastOneBackward

                    // console.log('backwardArrayAux - finalSlotsHoursInMinutes', backwardArrayAux + ' - ' + finalSlotsHoursInMinutes);
                    // console.log('containsAllBackward - containsNoneBackward', containsAllBackward + ' - ' + containsNoneBackward);

                    if (!containsAllBackward && !containsNoneBackward) {
                        isAvailableForJumps = false // No se puede reservar si no hay suficientes tramos disponibles
                    }
                }

                // Si todo está disponible, agregar la hora válida a finalValidHours
                if (isAvailableForJumps) {
                    let totalPrice = 0;
                    for (let jumps = 0; jumps < necessaryJumps; jumps++) {
                        const currentHourIndex = finalAllHoursInMinutes.indexOf(hour + (jumps * selectedDuration));
                        if (currentHourIndex !== -1) {
                            totalPrice += filteredPrices[currentHourIndex]; // Sumar el precio de cada tramo
                        }
                    }

                    // Si los tramos están disponibles, calcular la hora de finalización
                    let endHours = Math.floor((finalAllHoursInMinutes[index] + timeSelectedToRent) / 60);
                    let endMinutes = (finalAllHoursInMinutes[index] + timeSelectedToRent) % 60;
                    let endHour = `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;

                    // Agregar la hora válida con el precio total calculado
                    finalValidHours.push(filteredHours[index] + ' - ' + endHour + ' Precio: ' + totalPrice.toFixed(2) + '€');
                    arrayReservationsValues.push({
                        startHour: filteredHours[index],
                        endHour: endHour,
                        price: totalPrice
                    })
                }
            })

            // Actualizar las horas disponibles
            const newGroupedHours = [];
            for (let i = 0; i < finalValidHours.length; i += 2) {
                newGroupedHours.push(finalValidHours.slice(i, i + 2));
            }

            // Actualizar las horas disponibles
            setAvailableHours(finalValidHours);
            setGroupedHours(newGroupedHours);
            setReservationsValues(arrayReservationsValues);
        }
    }, [selectedDuration, selectedDate, court, reservations, reservationsLoaded, minDurationAll])
    
    useEffect(() => {
        if (clientauth?.id) {
            dispatch(getClient(clientauth?.client))
        }
    }, [clientauth, dispatch])

    useEffect(() => {
        if (items && items?.length) {
            let slots = []
            for (const item of items) {
                if (item.court && item.court._id === court._id) {
                    slots = item.slots
                }
            }
            setSelectedValues(slots)
        }
    }, [items, court])

    useEffect(() => {
        if (status === 'addSuccess') {
            Swal.fire({
                icon: 'success',
                text: t('cart.' + status),
                showConfirmButton: false,
                timer: 1200
            })
            // Reseteamos formulario
            setSelectedDate(() => {
                const date = new Date()
                date.setHours(0, 0, 0, 0)
                return date;
            })
            setSelectedDuration(durations[0])
            setPersonData({
                firstname: null,
                lastname: null,
                email: null,
                phone: null
            })
        }
    }, [status, dispatch, t, client, durations])

    useEffect(() => {
        if (client) {
            setPersonData({
                firstname: client.firstname,
                lastname: client.lastname,
                email: client.email,
                phone: client.phone
            })
        }
    }, [client])

    const handleValueClick = (index) => {
        if (selectedValues.some(v => v.startHour === reservationsValues[index].startHour && v.endHour === reservationsValues[index].endHour)) {
            setSelectedValues(selectedValues.filter(v => v.startHour !== reservationsValues[index].startHour && v.endHour !== reservationsValues[index].endHour))
        } else {
            setSelectedValues([...selectedValues, reservationsValues[index]])
        }
    }

    const handlePersonDataChange = ({target}) => {
        setPersonData({
            ...personData,
            [target.name]: target.value
        })
    }

    // Cuando se pulsa el botón de reservar
    const addToCart = async () => {
        if (!(clientauth && (!client?.phone || client?.phone === ""))) {
            if (!clientauth && (!personData.firstname || !personData.lastname || !personData.email || !personData.phone)) {
                Swal.fire({
                    icon: 'error',
                    text: t('marketplace.reservation.noPersonData'),
                    showConfirmButton: true,
                    confirmButtonText: t('global.accept'),
                    timer: 2000
                }) 
            } else {
                if (selectedValues.length > 0) {
                    dispatch(substituteCartItem({
                        court: court,
                        date: selectedDate,
                        duration: selectedDuration,
                        slots: selectedValues,
                        paymentClient: clientauth?.client,
                        personData: personData,
                        commission: commission.sites,
                        club: club
                    }))
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: t('marketplace.reservation.noSelectedValues'),
                        showConfirmButton: true,
                        confirmButtonText: t('global.accept'),
                        timer: 2000
                    })    
                }
            }
        } else {
            Swal.fire({
                icon: 'error',
                text: t('marketplace.reservation.noClientPhone'),
                showConfirmButton: true,
                confirmButtonText: t('global.accept'),
                timer: 2000
            })
        }
    }

    const calculatePrice = () => {
        if (selectedValues.length > 0) {
            let price = selectedValues.reduce((sum, value) => {
                let price = value?.price || 0
                if (price > 0) {
                    const sitesCommission = commission?.sites?.find(site => price >= site.lowPrice && price <= site.highPrice)
                    return parseFloat(sum) + parseFloat((Math.ceil((parseFloat(price) + parseFloat(price * (sitesCommission?.percentage || 0) / 100) + parseFloat(sitesCommission?.double || 0)) * 100) / 100).toFixed(2))
                } else {
                    return sum
                }
            }, 0)

            return parseFloat(price).toFixed(2)
        } else {
            return 0.00
        }
    }

    return (
            <Card className='border-0 p-4 card-banner cuerpo'>
                <Card.Body>
                    <div className='d-flex align-items-end flex-grow-1'>
                        <div className='flex-grow-1'>
                            <h2 className='border-2 border-bottom border-dark'>{t('marketplace.reservation.rentcourt')}</h2>
                        </div>
                    </div>
                    <Row>
                        <Col xs={12} md={6} className='mx-auto'>
                            {/* Selección de fecha */}
                            <Form.Group className='mt-3' controlId="selectDate">
                                <Form.Label>{t('marketplace.reservation.selectdate')}</Form.Label><br/>
                                <DatePicker
                                    locale={es}
                                    className='form-select'
                                    selected={selectedDate}
                                    onChange={date => {
                                        setSelectedDate(date)
                                        setSelectedValues([])
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    maxDate={addDays(new Date(), court.onwardsDays)}
                                    placeholderText={t('marketplace.reservation.selectdate')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {
                        !clientauth &&
                        <>
                            <Row>
                                <Col xs={12} md={6} className='my-3'>
                                    <Form.Group className='form-group'>
                                        <Form.Control
                                            type="text"
                                            name="firstname"
                                            value={personData.firstname || ''}
                                            className={personData.firstname ? 'has-content' : ''}
                                            onChange={handlePersonDataChange}
                                        />
                                        <Form.Label>{t('marketplace.reservation.firstname')}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className='my-3'>
                                    <Form.Group className='form-group'>
                                        <Form.Control
                                            type="text"
                                            name="lastname"
                                            value={personData.lastname || ''}
                                            className={personData.lastname ? 'has-content' : ''}
                                            onChange={handlePersonDataChange}
                                        />
                                        <Form.Label>{t('marketplace.reservation.lastname')}</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} className='my-3'>
                                    <Form.Group className='form-group'>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            value={personData.email || ''}
                                            className={personData.email ? 'has-content' : ''}
                                            onChange={handlePersonDataChange}
                                        />
                                        <Form.Label>{t('marketplace.reservation.email')}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className='my-3'>
                                    <Form.Group className='form-group'>
                                        <Form.Control
                                            type="number"
                                            name="phone"
                                            value={personData.phone || ''}
                                            className={personData.phone ? 'has-content' : ''}
                                            onChange={handlePersonDataChange}
                                        />
                                        <Form.Label>{t('marketplace.reservation.phone')}</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row>
                        <Col xs={12}>
                            {isDateBlocked ?
                                <div className="mt-5">
                                    {t('marketplace.reservation.horariesnotavailable')}
                                </div>
                            :
                                availableHours.length > 0 ?
                                    <Col xs={12} md={6} className="mt-5 mx-auto">
                                        <h3 className='text-center'>{t('marketplace.reservation.horariesavailable')}</h3>
                                        {durations.length > 0 && (
                                            <h3 className='text-center'>
                                                {durations[0] / 60} {durations[0] === 60 ? t('marketplace.reservation.hour') : t('marketplace.reservation.hours')}
                                            </h3>
                                        )}
                                        {groupedHours.map((group, index) => (
                                            <Row key={index} className='striped'>
                                                <Col xs={12} className='d-flex justify-content-between align-items-center my-1'>
                                                    <div>{group[0]}</div>
                                                    <div className={`btn p-2 ${selectedValues.some(v =>v.startHour === reservationsValues[index+index].startHour && v.endHour === reservationsValues[index+index].endHour) ? 'btn-danger btn-120' : 'btn-secondary'}`} onClick={() => { handleValueClick(index+index) }}>
                                                        {
                                                            selectedValues.some(v =>v.startHour === reservationsValues[index+index].startHour && v.endHour === reservationsValues[index+index].endHour) &&
                                                            <img src='/images/close-transparent.svg' alt='remove'/>
                                                        }
                                                        {t('marketplace.reservation.rent')}
                                                    </div>
                                                </Col>
                                                {group[1] ? (
                                                    <Col xs={12} className='d-flex justify-content-between align-items-center my-1'>
                                                        <div>{group[1]}</div>
                                                        <div className={`btn p-2 ${selectedValues.some(v =>v.startHour === reservationsValues[index+index+1].startHour && v.endHour === reservationsValues[index+index+1].endHour) ? 'btn-danger btn-120' : 'btn-secondary'}`} onClick={() => { handleValueClick(index+index+1) }}>
                                                            {
                                                                selectedValues.some(v =>v.startHour === reservationsValues[index+index+1].startHour && v.endHour === reservationsValues[index+index+1].endHour) &&
                                                                <img src='/images/close-transparent.svg' alt='remove'/>
                                                            }
                                                            {t('marketplace.reservation.rent')}
                                                        </div>
                                                    </Col>
                                                ) : (
                                                    <></>
                                                )}
                                            </Row>
                                        ))}
                                    </Col>
                                :
                                    <div className="mt-5">
                                        {t('marketplace.reservation.horariesnotavailable')}
                                    </div>
                            }
                        </Col>
                    </Row>
                    <Row className='mt-5 align-items-end'>
                        <Col xs={12} md={8}>
                            <h3 className='me-3 mb-0 font-single'>{t('matches.total')}:
                                <span className='ps-3'>{calculatePrice(selectedValues.reduce((sum, value) => {
                                    return parseFloat(sum) + parseFloat(value?.price)
                                }, 0))}€</span>
                            </h3>
                        </Col>
                        <Col xs={12} md={4} className='mt-4'>
                            {
                                (clientauth && (!client?.phone || client?.phone === ""))
                                ?
                                    <div className='text-danger'>
                                        {t('marketplace.reservation.noClientPhone')}
                                    </div>
                                :
                                    <></>
                            }
                            <div className='d-grid'>
                                <Button
                                    type='button'
                                    variant='primary'
                                    className={`d-flex justify-content-center align-items-center ${(clientauth && (!client?.phone || client?.phone === ""))
                                        ?
                                            'disabled'
                                        :
                                            ''
                                    }`}
                                    onClick={addToCart}>{t('marketplace.reservation.addToCart')} <img alt='' src='/images/rignt-arrow.svg' className='ms-3'/></Button>
                            </div>
                        </Col>
                        <hr className='my-1'/>
                    </Row>
                </Card.Body>
            </Card>

    );
}