export const reservationsReducer = (state = {loadingreservation: false, resultreservation: null, reservations: [], reservation: null}, action) => {
    switch (action.type) {
        case 'RESERVATION_LOADING':
            return {
                ...state,
                loadingreservation: true
            }
        case 'RESERVATION_NOT_LOADING':
            return {
                ...state,
                loadingreservation: false
            }
        case 'RESERVATION_GETALL_SUCCESS':
            return {
                ...state,
                loadingreservation: false,
                reservations: action.payload.reservations
            }
        case 'RESERVATION_ADD_SUCCESS':
            let arrayreservations = []
            state?.reservations?.map(async (reservation) =>{
                arrayreservations.push(reservation)
            })
            if(action.payload.message === 'successmulti'){
                action.payload.reservations?.map(async (reservation) =>{
                    arrayreservations.push(reservation)
                })
            }else{
                arrayreservations.push(action.payload.reservation)
            }

            return {
                ...state,
                loadingreservation:false,
                resultreservation: action.payload.message,
                reservations: arrayreservations
            }
        case 'RESERVATION_CANCEL_SUCCESS':
            let arrayCanceledReservations = []
            state.reservations?.map(reservation => {
                if (reservation._id === action.payload.reservation._id) {
                    arrayCanceledReservations.push(action.payload.reservation)
                } else {
                    arrayCanceledReservations.push(reservation)
                }
            })
            return {
                ...state,
                reservations: arrayCanceledReservations
            }
        case 'RESERVATION_RESET_RESULT':
            return {
                ...state,
                resultreservation: null
            }
        default:
            return state
    }
}