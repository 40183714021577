import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

/**
 * Prepares a purchase and gets a challenge URL
 * @param {Object} data JSON with payment data
 */
export const startPurchase = (data) => async (dispatch) => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const response = await Axios.post('api/payment/createPayment', data, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        if (!response.status || response.status > 299) {
            dispatch({
                type: 'PAYMENT_ERROR',
                payload: response.response.data.message || response.data.message || 'Unknown error'
            })
        } else {
            dispatch({
                type: 'PAYMENT_STARTPURCHASE_SUCCESS',
                payload: response?.data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_ERROR',
            payload: error.response.data
        })
    }
}

export const getPaymentStatus = (paymentId) => async (dispatch) => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const response = await Axios.get('api/payment/getPaymentStatus/' + paymentId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        if (!response.status || response.status > 299) {
            dispatch({
                type: 'PAYMENT_ERROR',
                payload: response.response.data.message || response.data.message || 'Unknown error'
            })
        } else {
            dispatch({
                type: 'PAYMENT_PAYMENT_STATUS_SUCCESS',
                payload: response?.data.status
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_ERROR',
            payload: error.response.data
        })
    }
}

export const payItems = (items) => async (dispatch) => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const response = await Axios.post('api/payment/payItems', items, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        if (!response.status || response.status > 299) {
            dispatch({
                type: 'PAYMENT_ERROR',
                payload: response.response.data.message || response.data.message || 'Unknown error'
            })
        } else {
            dispatch({
                type: 'PAYMENT_PAYMENT_REQUEST_SUCCESS',
                payload: response?.data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_ERROR',
            payload: error.response.data
        })
    }
}

export const getPaymentsInscription = (inscription, usertype) => async (dispatch) => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/payment/getpaymentsinscription/'+ inscription+'/'+usertype, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_NOT_LOADING'
        })
    }
}

export const getAllPayments = () => async dispatch => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/payment/getAllPayments', {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_NOT_LOADING'
        })
    }
}

export const getPaymentsByClub = club => async dispatch => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/payment/getPaymentsByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_NOT_LOADING'
        })
    }
}

export const getPaymentsByPaymentClient = paymentClient => async dispatch => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/payment/getPaymentsByPaymentClient/'+ paymentClient, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_NOT_LOADING'
        })
    }
}

export const editPaymentQuantity = (paymentId, typePayment, quantity) => async (dispatch) => {
    try {
        const { data, status } = await Axios.put('/api/payment/edit/' + paymentId, {paymentType: typePayment, quantity}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_NOT_LOADING'
        })
    }
}

export const paySepa = (user, club) => async (dispatch) => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const response = await Axios.post('api/payment/paySepa', {user, club}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        if (!response.status || response.status > 299) {
            dispatch({
                type: 'PAYMENT_SEPA_RESULT',
                payload: response.response.data.message || response.data.message || 'Unknown error'
            })
        } else {
            dispatch({
                type: 'PAYMENT_SEPA_RESULT',
                payload: response?.data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_SEPA_RESULT',
            payload: error.response.data
        })
    }
}

export const editPaymentExternalStatus = (paymentId, externalPaid) => async (dispatch) => {
    try {
        const { data, status } = await Axios.put('/api/payment/edit/' + paymentId, {externalPaid}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_NOT_LOADING'
        })
    }
}

export const retryPayment = (paymentId) => async (dispatch) => {
    // dispatch({
    //     type: 'PAYMENT_LOADING'
    // })
    try {
        const { data, status } = await Axios.post('/api/payment/retryPayment/' + paymentId, {}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_RETRY_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        // dispatch({
        //     type: 'PAYMENT_NOT_LOADING'
        // })
    }
}

export const returnPayment = (paymentId, quantity) => async (dispatch) => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const { data, status } = await Axios.put('/api/payment/returnPayment/' + paymentId, {quantity}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_RETURN_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_NOT_LOADING'
        })
    }
}

export const dashboardAdmin = () => async (dispatch) => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/payment/dashboardadmin' , {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_GETRESUME_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_NOT_LOADING'
        })
    }
}

/**
 * Prepara el carrito y pago para usuarios no loggeados
 * @param {Object} data JSON with payment data
 */
export const startPublicPurchase = (items) => async (dispatch) => {
    dispatch({
        type: 'PAYMENT_LOADING'
    })
    try {
        const response = await Axios.post('api/payment/createPublicPayment', items, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        if (!response.status || response.status > 299) {
            dispatch({
                type: 'PAYMENT_ERROR',
                payload: response.response.data.message || response.data.message || 'Unknown error'
            })
        } else {
            dispatch({
                type: 'PAYMENT_START_PUBLIC_PURCHASE_SUCCESS',
                payload: response?.data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_ERROR',
            payload: error.response.data
        })
    }
}

export const changePaymentCard = (paymentId, paycometId, usertype = null) => async (dispatch) => {
    try {
        const { data, status } = await Axios.put('/api/payment/changepaymentcard/' + paymentId, {paycometId, usertype}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PAYMENT_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PAYMENT_NOT_LOADING'
        })
    }
}