import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getReservationPublicByCourtAndDate = (court, date) => async (dispatch) => {
    dispatch({
        type: 'RESERVATION_LOADING'
    })
    try {
        const { data, status } = await Axios.get(`/api/reservation/public/getreservationpublicbycourtanddate?court=${court}&date=${date}`)
        if (status === 200) {
            dispatch({
                type: 'RESERVATION_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESERVATION_NOT_LOADING'
        })
    }
}

export const rentCourt = (reservationData) => async (dispatch) => {
    dispatch({
        type: 'RESERVATION_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/reservation/register', reservationData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'RESERVATION_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESERVATION_NOT_LOADING'
        })
    }
}

export const getReservationsByClubAndDate = (club, date) => async (dispatch) => {
    dispatch({
        type: 'RESERVATION_LOADING'
    })
    try {
        const { data, status } = await Axios.get(`/api/reservation/getreservationsbyclubanddate?club=${club}&date=${date}`)
        if (status === 200) {
            dispatch({
                type: 'RESERVATION_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESERVATION_NOT_LOADING'
        })
    }
}

export const getReservationsByClient = (client) => async (dispatch) => {
    dispatch({
        type: 'RESERVATION_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/reservation/getreservationsbyclient/' + client, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'RESERVATION_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESERVATION_NOT_LOADING'
        })
    }
}

export const cancelReservation = (reservationId) => async (dispatch) => {
    dispatch({
        type: 'RESERVATION_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/reservation/cancelreservation/' + reservationId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'RESERVATION_CANCEL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESERVATION_NOT_LOADING'
        })
    }
}