import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import Loader from '../../../../components/design/Loader/Loader'
import { getPasses, getPassesByClub } from '../../../../actions/ticketing/passesActions'
import PassSummary from '../../../../components/ticketing/PassSummary'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'
import TicketingSubMenu from '../../../../components/menus/TicketingSubMenu'
import Swal from 'sweetalert2'
import { sendEmailDownloadEntry } from '../../../../actions/downloads/downloadsActions'
import QRCode from 'qrcode';
import AddPass from '../../../../components/ticketing/AddPass'

export default function Passes({usertype, isAdmin = false}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {passes, loading, result} = useSelector(state=>state.pass)
    const {resultdownload} = useSelector(state=>state.downloads)
    const {club} = useSelector(state=>state.clubs)

    const [passSelected, setPassSelected] = useState(null)
    const [formType, setFormType] = useState(null)
    
    const [addPass, setAddPass] = useState(false)

    const handleSendEmail = (e, passId) => {
        e.preventDefault()

        dispatch(sendEmailDownloadEntry(passId))
    }

    const QRCodeImage = ({ identifier }) => {
        const [qrImageUrl, setQrImageUrl] = useState(null);

        useEffect(() => {
          const generateQRCode = async () => {
            try {
              const qrImageUrl = await QRCode.toDataURL(identifier);
              setQrImageUrl(qrImageUrl);
            } catch (error) {
              console.error(error);
            }
          };
          generateQRCode();
        }, [identifier]);

        return qrImageUrl ? <img src={qrImageUrl} alt="qr code" style={{width: '200px', height: '200px'}}/> : null;
    };

	const columnsPasses = [
        {
            name: t('passes.season'),
            cell: pass =>
                pass.season?.name,
            selector: (pass) => pass.season?.name,
            sortable: true,
        },
        {
            name: t('passes.passNumber'),
            width: '100px',
            cell: pass =>
                pass?.passNumber !== null ? pass.passNumber : '',
            selector: (pass) => pass?.passNumber,
            sortable: true,
        },
        // {
        //     name: 'QR CODIGOOOOOO',
        //     width: "320px",
        //     cell: pass =>
        //         <div style={{width: '100%', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        //             <QRCodeImage identifier={pass?.identifier} style={{width: '100%', height: '100%'}}/>
        //         </div>,
        //     selector: (pass) => pass.season.name,
        //     sortable: true,
        // },
        {
            name: t('passes.sector'),
            cell: pass =>
                pass.sector?.name,
            selector: (pass) => pass.sector?.name,
            sortable: true,
        },{
            name: t('passes.status'),
            width: "120px",
            center: 'true',
            cell: pass =>
                pass.status === 0 ? t('passes.pending') :
                pass.status === 1 ? t('passes.active') :
                pass.status === 2 ? t('passes.canceled') :
                t('passes.unknown'),
            selector: (pass) =>
                pass.status === 0 ? t('passes.pending') :
                pass.status === 1 ? t('passes.active') :
                pass.status === 2 ? t('passes.canceled') :
                t('passes.unknown'),
            sortable: true,
        },{
            name: t('passes.price'),
            center: 'true',
            cell: pass => (
                    <div>
                        {pass.price} €
                    </div>
            ),
            selector: (pass) => pass.price,
            sortable: true,
        },{
            name: t('passes.client'),
            width: '30%',
            cell: pass => (
                <div>
                    {`${pass.client ? pass.client?.firstname : pass.personData?.firstname} ${pass.client ? pass.client?.lastname : pass.personData?.lastname ? pass.personData?.lastname : ''}`}
                    <br />
                    <small>Token: {pass.identifier}</small><br/>
                    <small>{pass.passType?.name}</small>
                </div>
            ),
            selector: (pass) => `${pass.identifier} ${pass.client ? pass.client?.firstname : pass.personData?.firstname} ${pass.client ? pass.client?.lastname : pass.personData?.lastname} ${pass.passType?.name || ''}`,
            sortable: true,
        },{
            name: t('passes.physical'),
            center: 'true',
            cell: pass => {
                const abonoFisico = pass.questions?.find(q => q.question === "Abono físico")
                return abonoFisico ? abonoFisico.answer : ""
            },
            selector: pass => {
                const abonoFisico = pass.questions?.find(q => q.question === "Abono físico")
                return abonoFisico ? abonoFisico.answer : ""
            },
            sortable: true,
        },{
            name: t('global.actions'),
            center: 'true',
            width: '150px',
            cell: pass =>
                <div className='d-flex w-100 justify-content-center'>
                    <div
                        onClick={() => {
                            setPassSelected(pass)
                            setFormType("view")
                        }}
                        className='table-link me-2'>
                        <OverlayTrigger placement="top"
                            overlay={<Tooltip id="tooltip">{t('passes.seePass')}</Tooltip>}
                        >
                            <img className="cursor" src="/images/eyeopen.svg" alt="eyes" height={16}/>
                        </OverlayTrigger>
                    </div>
                    <div
                        onClick={(e) => {
                            handleSendEmail(e, pass?._id)
                        }}
                        className='table-link me-2'>
                        <OverlayTrigger placement="top"
                            overlay={<Tooltip id="tooltip">{t('passes.sendemail')}</Tooltip>}
                        >
                            {/* <img src="/images/envelope.svg" alt="Email" className='me-2 my-2' /> */}
                            <i className='far fa-envelope'></i>
                        </OverlayTrigger>
                    </div>
                    <div
                        onClick={() => {
                            setPassSelected(pass)
                            setFormType("edit")
                        }}
                        className='table-link'>
                        <OverlayTrigger placement="top"
                            overlay={<Tooltip id="tooltip">{t('passes.editPass')}</Tooltip>}
                        >
                            <img className="cursor" src="/images/pencil.svg" alt="pencil" height={16}/>
                        </OverlayTrigger>
                    </div>
                </div>,
            sortable: false,
        }
	]

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (isAdmin) {
            dispatch(getPasses())
        } else {
            if(club){
                dispatch(getPassesByClub(club._id))
            }
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth])

    useEffect(() => {
        if (result) {
            switch (result) {
                case 'changeStatusSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('passes.changeStatusSuccess')
                    })
                    setPassSelected(null)
                    setFormType(null)
                    // dispatch(getPassesByClub(club._id))
                    dispatch({ type: "PASS_RESULT_RESET" })
                    break;
                default:
                    break;
            }
        }
    }, [result, club, dispatch, t])

    useEffect(() => {
        if (resultdownload) {
            let newresult = resultdownload
            dispatch({
                type: 'DOWNLOAD_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('passes.emailsendsuccess')
                    })
                    break;
                case 'notentryexist':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('passes.notentryexist')
                    })
                    break;
                case 'notemailexist':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('passes.notemailexist')
                    })
                    break;
                default:
                    break;
            }
        }
    }, [resultdownload, dispatch, t])

    return (
        <Container className='cuerpo'>
            {
                isAdmin
                ?
                    <Row>
                        <Col className='d-flex align-items-center'>
                            <h1>{t('menus.ticketing.passes')}</h1>
                        </Col>
                    </Row>
                :
                <>
                    <ClubBreadCrumbs usertype={usertype} club={club} />
                    <Row className="my-3">
                        <Col>
                            <TicketingSubMenu usertype={usertype} active="passes"/>
                        </Col>
                    </Row>
                </>
            }
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className='d-flex justify-content-between align-items-end'>
                                <h2>{t('passes.passesList')}</h2>
                                <div>
                                    <Button variant="primary" type="button" className='ms-2' onClick={() => setAddPass(true)}>
                                        <i className='fas fa-plus'/> {t('passes.addPass')}
                                    </Button>
                                </div>
                            </div>
                            <hr className='mb-4'/>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            loading
                                                ?
                                                    <Loader/>
                                                :
                                                    <>
                                                        {
                                                            passes?.length > 0 ?
                                                                <CustomDataTable
                                                                    columns={columnsPasses}
                                                                    data={passes}
                                                                    exportable={false}
                                                                    printable={false}
                                                                />
                                                            :
                                                                <div className='contenedor-vacio text-center'>
                                                                    <h4>{t('passes.noPasses')}</h4>
                                                                    {t('passes.thereIsNoPassesPhrase')}
                                                                </div>
                                                        }
                                                    </>
                                        }
                                        
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <PassSummary userType="client" passSelected={passSelected} closeModal={() => setTimeout(() => setPassSelected(null), 300)} formType={formType} usertype={usertype}/>
            <AddPass userType="club" closeModal={() => {setAddPass(false)}} show={addPass} club={club}/>
        </Container>
    )
}