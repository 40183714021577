import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'

export default function SitesSubMenu({usertype, active}) {
    const { t } = useTranslation()

    const {club} = useSelector(state=>state.clubs)

    return (
        <Container fluid className='submenu px-0 mb-3'>
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('club.sites')}</h1>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/site" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "sites"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sites")}` : `/${t("url.club.club")}/${t("url.club.sites")}`}>{t('menus.sites.sites')}</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "zones"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.zones")}` : `/${t("url.club.club")}/${t("url.club.zones")}`}>{t('menus.sites.zones')}</Nav.Link>
                        </Nav.Item> */}
                        {club?.renting ?
                            <>
                                <Nav.Item as="li">
                                    <Nav.Link as={Link} active={active === "courts"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.courts")}` : `/${t("url.club.club")}/${t("url.club.courts")}`}>{t('menus.sites.courts')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link as={Link} active={active === "courtsrents"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.courtsrents")}` : `/${t("url.club.club")}/${t("url.club.courtsrents")}`}>{t('menus.sites.courtsrents')}</Nav.Link>
                                </Nav.Item>
                            </>
                        :
                            <></>
                        }
                        {/* <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "areas"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.areas")}` : `/${t("url.club.club")}/${t("url.club.areas")}`}>{t('menus.sites.areas')}</Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}