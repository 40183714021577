import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, CardBody, Form, Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import useAuth from '../../../../context/auth/useAuth'
import { getCourtsByClub } from '../../../../actions/sites/courtsActions'
import { getReservationsByClubAndDate, rentCourt, cancelReservation } from '../../../../actions/sites/reservationsActions'
import SitesSubMenu from '../../../../components/menus/SitesSubMenu'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'
import Swal from 'sweetalert2'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import Select from "react-select"

export default function CourtsRent({usertype}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {courts} = useSelector(state=>state.courts)
    const {reservations, resultreservation} = useSelector(state=>state.reservations)
    const {club} = useSelector(state=>state.clubs)

    const [show, setShow] = useState(false)
    const [isPrivate, setIsPrivate] = useState(false)
    const handleClose = () => setShow(false)

    const [formState, setFormState] = useState({
        court: null,
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        date: null,
        startHour: '00:00',
        endHour: '00:00',
        duration: 0,
        price: 0,
        status: 1
    })

    const handleInputChange = ({target}) => {
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handlePeriodChange = (value) => {
        setDateNewRent([value ? value[0] : null, value ? value[1] : null])
    }

    const handleDaysChange = (selectedOptions) => {
        let combinedDays = [...new Set([...formState.weekDays, ...selectedOptions])]

        setFormState({
            ...formState,
            weekDays: combinedDays
        })
    }

    const [dateNewRent, setDateNewRent] = useState(() => {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        const madridDate = new Date(date).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric', month: '2-digit', day: '2-digit'})
        return new Date(madridDate)
    })

    const [selectedDate, setSelectedDate] = useState(() => {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        const madridDate = new Date(date).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric', month: '2-digit', day: '2-digit'})
        return new Date(madridDate)
    })

    const [groupedReservations, setGroupedReservations] = useState([])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth && club){
            dispatch(getCourtsByClub(club?._id))
        }
    // eslint-disable-next-line
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if(isPrivate === true || isPrivate === false){
            setFormState({
                court: null,
                name: '',
                phone: '',
                email: '',
                weekDays: [],
                startHour: '00:00',
                endHour: '00:00',
                duration: 0,
                price: 0,
                status: 1
            })

            if(isPrivate){
                setDateNewRent(null)
                setFormState({
                    ...formState,
                    weekDays: []
                })
            }else{
                setDateNewRent(() => {
                    const date = new Date()
                    date.setHours(0, 0, 0, 0)
                    const madridDate = new Date(date).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric', month: '2-digit', day: '2-digit'})
                    return new Date(madridDate)
                })
                setFormState({
                    ...formState,
                    weekDays: []
                })
            }
        }
    // eslint-disable-next-line
    },[isPrivate])

    useEffect(() => {
        if(clientauth && club && selectedDate){
            const madridTimeZone = new Date(selectedDate).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric',month: '2-digit', day: '2-digit'})

            dispatch(getReservationsByClubAndDate(club?._id, madridTimeZone))
        }
    // eslint-disable-next-line
    },[selectedDate, clientauth, club])

    useEffect(() => {
        if (reservations) {
            const grouped = {}
            reservations?.forEach(reservation => {
                if (!reservation.date) return; // Validación para asegurar que 'date' esté presente
                const courtId = reservation.court
                const reservationDay = format(new Date(reservation.date), 'yyyy-MM-dd') // Asegúrate de que 'date' es válida
    
                if (!grouped[courtId]) {
                    grouped[courtId] = {}
                }
    
                if (!grouped[courtId][reservationDay]) {
                    grouped[courtId][reservationDay] = []
                }

                grouped[courtId][reservationDay].push(reservation)
            })

            setGroupedReservations(grouped)
        }
    }, [reservations])

    const newRentCourt = async(e) => {
        e.preventDefault()

        let weekDaysSaved = []

        if(formState.weekDays.length > 0){
            for(const day of formState.weekDays) {
                weekDaysSaved.push(day.value)
            }
        }

        dispatch(rentCourt({...formState, date: dateNewRent, weekDays: weekDaysSaved, club: club?._id}))
    }

    const handleCancelReservation = async(e, reservationId) => {
        e.preventDefault()

        Swal.fire({
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: t('global.yes'),
            cancelButtonText: t('global.no'),
            icon: 'warning',
            text: t('reservations.cancelsecure')
        }).then((newresult) => {
            if (newresult.isConfirmed) {
                dispatch(cancelReservation(reservationId))
            }
        })
    }

    useEffect(() => {
        if(resultreservation){
            switch (resultreservation) {
                case 'success':
                case 'successmulti':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'success',
                        text: t('reservations.addsuccess')
                    }).then(() => {
                        handleClose()
                        const date = new Date()
                        date.setHours(0, 0, 0, 0)
                        const madridDate = new Date(date).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric', month: '2-digit', day: '2-digit'})

                        setDateNewRent(new Date(madridDate))

                        setFormState({
                            court: null,
                            firstname: '',
                            lastname: '',
                            phone: '',
                            email: '',
                            date: new Date(madridDate),
                            startHour: '00:00',
                            endHour: '00:00',
                            duration: 0,
                            price: 0,
                            status: 1
                        })
                    })
                    dispatch({
                        type: 'RESERVATION_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [resultreservation])

    const [showModal, setShowModal] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState(null);

    const handleShowModal = (reservation) => {
        setSelectedReservation(reservation)
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setSelectedReservation(null)
        setShowModal(false)
    }

    // Generar intervalos de 30 minutos en el día
    const generateTimeSlots = (startHour, endHour) => {
        const slots = []
        let currentHour = Math.floor(startHour)
        let currentMinute = Math.round((startHour - currentHour) * 60)

        while (currentHour < Math.floor(endHour) || (currentHour === Math.floor(endHour) && currentMinute < (endHour - Math.floor(endHour)) * 60)) {
            const start = `${currentHour < 10 ? `0${currentHour}` : currentHour}:${currentMinute < 10 ? `0${currentMinute}` : currentMinute}`
            let endHourDisplay = currentHour
            let endMinute = (currentMinute + 30) % 60
            if (endMinute === 0) {
                endHourDisplay = (endHourDisplay + 1) % 24
            }
            const end = `${endHourDisplay < 10 ? `0${endHourDisplay}` : endHourDisplay}:${endMinute < 10 ? `0${endMinute}` : endMinute}`
            slots.push({ start, end })

            currentMinute += 30
            if (currentMinute >= 60) {
                currentHour++
                currentMinute = 0
            }
        }

        return slots
    }

    // Función para obtener intervalos de media hora en el rango de reserva
    const getSlotsInRange = (start, end) => {
        const [startHour, startMinute] = start.split(':').map(Number)
        const [endHour, endMinute] = end.split(':').map(Number)

        const slots = []
        let currentHour = startHour
        let currentMinute = startMinute

        while (currentHour < endHour || (currentHour === endHour && currentMinute < endMinute)) {
            slots.push(`${currentHour}:${currentMinute === 0 ? '00' : '30'}`)
            currentMinute += 30
            if (currentMinute === 60) {
                currentMinute = 0
                currentHour += 1
            }
        }

        return slots
    }

    // Asegura que el formato de tiempo esté en formato 'HH:mm'
    const formatTime = (time) => {
        const [hours, minutes] = time.split(':').map(Number)
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
    }

    return (
        <>
            <Container className='cuerpo'>
                <ClubBreadCrumbs usertype={usertype} club={club} />
                <Row className="my-3">
                    <Col>
                        <SitesSubMenu usertype={usertype} active="courtsrents"/>
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col>
                        <Card>
                            <CardBody>
                                <h2>{t('courts.courtsrentslist')}</h2>
                                <hr className='mb-4'/>
                                <Row className='my-3'>
                                    <Col xs={12} md={6}>
                                        {/* Selección de fecha */}
                                        <Form.Group controlId="selectDate">
                                            <Form.Label>{t('marketplace.reservation.selectdate')}</Form.Label><br/>
                                            <DatePicker
                                                locale={es}
                                                className='form-select'
                                                selected={selectedDate}
                                                onChange={date => setSelectedDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText={t('marketplace.reservation.selectdate')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <div className='buttons d-flex justify-content-end mt-3'>
                                            <Button variant="default" onClick={() => {setIsPrivate(false); setShow(true)}}>
                                                <i className='fas fa-plus'></i> {t('courts.newrent')}
                                            </Button>
                                            <Button variant="default" className='ms-3' onClick={() => {setIsPrivate(true); setShow(true)}}>
                                                <i className='fas fa-plus'></i> {t('courts.newrentprivate')}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                <Col xs={12}>
                                    {courts?.length > 0 ? (
                                    courts.map(court => (
                                        <Card key={court._id} className="my-3">
                                        <Card.Body>
                                            <h5>{court.name} ({t('courts.nextdays')})</h5>
                                            <hr />
                                            <Row>
                                            {Object.keys(groupedReservations[court._id] || {}).sort((a, b) => new Date(a) - new Date(b)).map(day => (
                                                <Col key={day} className="border p-1">
                                                    <h6 className="text-center">
                                                        {new Intl.DateTimeFormat('es-ES', { day: 'numeric', month: 'long', weekday: 'long' }).format(new Date(day))}
                                                    </h6>
                                                    <div>
                                                        {court.schedules.filter(sch => sch.weekDays.includes(format(new Date(day), 'eeee').toLowerCase()) &&
                                                            (!sch.startDate || new Date(day) >= new Date(sch.startDate)) &&
                                                            (!sch.endDate || new Date(day) <= new Date(sch.endDate))).map(sch => {
                                                            const [startHours, startMinutes] = sch.startHour.split(':').map(Number)
                                                            const newStartHour = startHours + (startMinutes / 60)
                                                            const [endHours, endMinutes] = sch.endHour.split(':').map(Number)
                                                            const newEndHour = endHours + (endMinutes / 60)
                                                            return generateTimeSlots(newStartHour, newEndHour).map(slot => {
                                                                const normalizedSlotStart = formatTime(slot.start)
                                                                const reservationsForSlot = (groupedReservations[court._id][day] || []).filter(res => {
                                                                    const reservedSlots = getSlotsInRange(res.startHour, res.endHour).map(formatTime)
                                                                    return reservedSlots.includes(normalizedSlotStart)
                                                                })
                                                                const isReserved = reservationsForSlot.length > 0

                                                                const color = () => {
                                                                    if (reservationsForSlot.some(res => res.status === 1)) return 'bg-danger text-white'
                                                                    if (reservationsForSlot.some(res => res.status === 0)) return 'bg-warning text-dark'
                                                                    if (reservationsForSlot.some(res => res.status === 2)) return 'bg-primary text-dark'
                                                                    return 'bg-light'
                                                                }

                                                                return (
                                                                    <div key={slot.start}
                                                                        className={`p-1 text-center small ${color()}`}
                                                                        style={{ cursor: isReserved ? 'pointer' : 'default', height: '25px', lineHeight: '25px' }}
                                                                        onClick={() => isReserved && handleShowModal(reservationsForSlot)}
                                                                    >
                                                                        {slot.start} - {slot.end}
                                                                    </div>
                                                                )
                                                            })
                                                        })}
                                                    </div>
                                                </Col>
                                            ))}
                                            </Row>
                                        </Card.Body>
                                        </Card>
                                    ))
                                    ) : (
                                    <p>{t('courts.thereisnotcourts')}</p>
                                    )}
                                </Col>

                                {/* Modal para mostrar detalles de la reserva */}
                                <Modal show={showModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t('courts.reservationdetails')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    {selectedReservation?.length > 0 ? (
                                        selectedReservation.map((reservation, index) => (
                                            <React.Fragment key={reservation._id}>
                                                <p><strong>{t('courts.time')}:</strong> {reservation.startHour} - {reservation.endHour}</p>
                                                <p><strong>{t('courts.reservationof')}:</strong> {reservation.paymentClient
                                                    ? `${reservation.paymentClient.firstname} ${reservation.paymentClient.lastname} (${reservation.paymentClient.phone || t('courts.nophone')} - ${reservation.paymentClient.email || t('courts.noemail')})`
                                                    : `${reservation.personData.firstname} ${reservation.personData.lastname} (${reservation.personData.phone || t('courts.nophone')} - ${reservation.personData.email || t('courts.noemail')})`
                                                }</p>
                                                <p><strong>{t('courts.status')}:</strong> {reservation.status === 1 ? t('courts.confirmed') : reservation.status === 0 ? t('courts.pending') : t('courts.canceled')}</p>
                                                {(reservation.status === 0 || reservation.status === 1) && (
                                                    <Button variant="danger" onClick={(e) => {
                                                        handleCancelReservation(e, reservation._id);
                                                        handleCloseModal();
                                                    }}>
                                                        {t('courts.cancelreservation')}
                                                    </Button>
                                                )}
                                                {index < selectedReservation.length - 1 && <hr />}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <p>{t('courts.noreservationdetails')}</p>
                                    )}
                                    </Modal.Body>
                                </Modal>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{t('reservations.newrent')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo px-5 py-4'>
                    <Form onSubmit={newRentCourt}>
                        {/* Seleccionar pista */}
                        <Row className="my-3">
                            <Col xs={12} lg={6}>
                                <Form.Group className='form-group'>
                                    <Form.Select
                                        name='court'
                                        className='has-content form-control'
                                        value={formState?.court || ''}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">{t('reservations.selectcourt')}</option>
                                        {courts?.length > 0 && courts.map(court => (
                                            <option key={court._id} value={court._id}>
                                                {court.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Label htmlFor='court'>{t('reservations.court')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col xs={12} md={6}>
                                {/* Seleccionar firstname */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.firstname ? 'has-content' : ''}
                                        type='text'
                                        name='firstname'
                                        value={formState?.firstname || ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='firstname'>{t('reservations.firstname')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                {/* Seleccionar lastname */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.lastname ? 'has-content' : ''}
                                        type='text'
                                        name='lastname'
                                        value={formState?.lastname || ''}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Label htmlFor='lastname'>{t('reservations.lastname')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col xs={12} md={6}>
                                {/* Seleccionar phone */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.phone ? 'has-content' : ''}
                                        type='text'
                                        name='phone'
                                        value={formState?.phone || ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='phone'>{t('reservations.phone')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                {/* Seleccionar email */}
                                <Form.Group className="form-group">
                                    <Form.Control
                                        className={formState?.email ? 'has-content' : ''}
                                        type="text"
                                        name="email"
                                        value={formState?.email}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Label htmlFor='price'>{t('reservations.email')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                                {/* Seleccionar date */}
                                {isPrivate ?
                                    <>
                                        <Col xs={12} md={6}>
                                            <Form.Group className='form-group'>
                                                <DateRangePicker
                                                    className="has-content form-control"
                                                    onChange={(value) => {handlePeriodChange(value)}}
                                                    value={dateNewRent ? [dateNewRent[0], dateNewRent[1]] : [null, null]}
                                                    required
                                                />
                                                <Form.Label>{t('schedule.period')}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="form-group">
                                                <Select
                                                    className="form-control has-content"
                                                    name="weekDays"
                                                    options={[
                                                        { value: 1, label: t('schedule.monday') },
                                                        { value: 2, label: t('schedule.tuesday') },
                                                        { value: 3, label: t('schedule.wednesday') },
                                                        { value: 4, label: t('schedule.thursday') },
                                                        { value: 5, label: t('schedule.friday') },
                                                        { value: 6, label: t('schedule.saturday') },
                                                        { value: 0, label: t('schedule.sunday') },
                                                    ]}
                                                    value={formState?.weekDays || []}
                                                    onChange={(selectedOptions) => handleDaysChange(selectedOptions)}
                                                    isMulti
                                                    required
                                                />
                                                <Form.Label htmlFor="weekDays">{t('schedule.weekdays')}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </>
                                :
                                    <Col xs={12} md={6}>
                                        <Form.Group controlId="selectDate">
                                            <Form.Label>{t('marketplace.reservation.selectdate')}</Form.Label>
                                            <DatePicker
                                                locale={es}
                                                className='form-select'
                                                selected={dateNewRent}
                                                onChange={date => setDateNewRent(date)}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText={t('marketplace.reservation.selectdate')}
                                            />
                                        </Form.Group>
                                    </Col>
                                }
                        </Row>
                        <Row className='my-3'>
                            <Col xs={12} md={4}>
                                {/* Seleccionar startHour */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.startHour ? 'has-content' : ''}
                                        type='time'
                                        name='startHour'
                                        value={formState?.startHour || '00:00'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='startHour'>{t('reservations.starthour')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                {/* Seleccionar endHour */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.endHour ? 'has-content' : ''}
                                        type='time'
                                        name='endHour'
                                        value={formState?.endHour || '00:00'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='endHour'>{t('reservations.endhour')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                {/* Seleccionar price */}
                                <Form.Group className="form-group">
                                    <Form.Control
                                        className="has-content"
                                        type="number"
                                        name="price"
                                        value={formState?.price}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <img src="/images/money.svg" alt="Money" className="icono-money" />
                                    <Form.Label htmlFor='price'>{t('reservations.price')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col xs={12} lg={6} className='ms-auto'>
                                <Button variant='primary' type="submit" className="w-100 my-2">
                                    {t('reservations.rent')}
                                </Button>
                            </Col>
                        </Row>


                        {/* Seleccionar duration */}
                        {/* <Form.Group className="form-group">
                            <Form.Control
                                className="has-content"
                                type="number"
                                name="duration"
                                value={formState?.duration}
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Label htmlFor='duration' className='mt-2'>{t('reservations.duration')}</Form.Label>
                        </Form.Group> */}


                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}