import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { PDFDownloadLink } from '@react-pdf/renderer'
import Ticket from '../downloads/Ticket'
import { useDispatch } from 'react-redux'
import useAuth from '../../context/auth/useAuth'
import { changeTicketStatus } from '../../actions/ticketing/ticketsActions'

export default function TicketSummary({usertype, ticketSelected, closeModal, formType}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const handleStatusChange = (status) => {
        dispatch(changeTicketStatus(ticketSelected?._id, clientauth.id, status))
    }

    return (
        <Modal show={ticketSelected ? true : false} onHide={closeModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('tickets.ticketData')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='cuerpo px-5 py-4'>
                <Row className="mb-5">
                    <Col xs={12} md={8}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.identifier')}:</small>
                            <div className='text-end'>
                                {ticketSelected?.identifier}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.club')}:</small>
                            <div className='text-end'>
                                {ticketSelected?.club?.name}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.match')}:</small>
                            <div className='text-end'>
                                {`${ticketSelected?.match?.host} - ${ticketSelected?.match?.visitor}`}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.date')}:</small>
                            <div className='text-end'>
                                {`${new Date(ticketSelected?.match?.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${ticketSelected?.match?.hour}`}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.client')}:</small>
                            <div className='text-end'>
                                {`${ticketSelected?.client ? ticketSelected?.client.firstname : ticketSelected?.personData.firstname} ${ticketSelected?.client ? ticketSelected?.client.lastname : ticketSelected?.personData.lastname || ''}`}
                            </div>
                        </div>
                        {
                            (ticketSelected?.pass || ticketSelected?.fee) &&
                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                <small>{ticketSelected?.pass ? t('tickets.passType') : t('tickets.fee')}:</small>
                                <div className='text-end'>
                                    {
                                        ticketSelected?.pass
                                        ?
                                            ticketSelected.pass.passType?.name
                                        :
                                            ticketSelected?.fee?.name
                                    }
                                </div>
                            </div>
                        }
                        {
                            ticketSelected?.seat
                            ?
                                <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                    <small>{t('tickets.seat')}:</small>
                                    <div className='text-end'>
                                        {`${t("tickets.row")} ${parseInt(ticketSelected.seat.row) + 1}, ${t("tickets.column")} ${parseInt(ticketSelected.seat.column) + 1}`}
                                    </div>
                                </div>
                            :
                                <></>
                        }
                        {
                            ticketSelected?.type === "invitation" && ticketSelected?.maxUses
                            ?
                                <>
                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                        <small>{t('tickets.maxUses')}:</small>
                                        <div className='text-end'>
                                            {ticketSelected?.maxUses}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                        <small>{t('tickets.uses')}:</small>
                                        <div className='text-end'>
                                            {ticketSelected?.uses || 0}
                                        </div>
                                    </div>
                                </>
                            :
                                <></>
                        }
                    </Col>
                    <Col xs={12} md={4}>
                        <div className='p-2 text-center'>
                            <small>{t('tickets.status')}</small>
                            <div className='fw-bold'>
                                {
                                    ticketSelected?.status === 0 ? t('tickets.pending') :
                                    ticketSelected?.status === 1 ? t('tickets.active') :
                                    ticketSelected?.status === 2 ? t('tickets.canceled') :
                                    t('tickets.unknown')
                                }
                            </div>
                        </div>
                        {
                            formType === "edit" && (usertype === "club" || usertype === "superadmin")
                            ?
                                <div className='mt-4 text-center'>
                                    <small>{t('tickets.changeStatus')}</small>
                                    {
                                        (ticketSelected?.status === 0 || ticketSelected?.status === 2) &&
                                        <div className='btn btn-secondary p-1 my-3' onClick={() => handleStatusChange(1)}>{t('tickets.toActive')}</div>
                                    }
                                    {
                                        (ticketSelected?.status === 0 ||ticketSelected?.status === 1) &&
                                        <div className='btn btn-default p-1' onClick={() => handleStatusChange(2)}>{t('tickets.toCancelled')}</div>
                                    }
                                </div>
                            :
                                <></>
                        }
                    </Col>
                </Row>
                {
                    ticketSelected &&
                    <Row className='my-3'>
                        <Col xs={12} md={6} className='ms-auto'>
                            <PDFDownloadLink document={<Ticket ticket={ticketSelected}/>} fileName={`${ticketSelected?.identifier}.pdf`}>
                                <Button type='button' className='w-100 my-2' variant='default'>
                                    <img src='/images/download.svg' alt='download'/>
                                </Button>
                            </PDFDownloadLink>
                        </Col>
                    </Row>
                }
            </Modal.Body>
        </Modal>
	)
}