import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import QRCode from "qrcode";

Font.register({
    family: 'Northlane Two',
    src: '/fonts/Northlane/Northlane-Two.ttf',
    fontWeight: 'normal',
});

export default function Ticket({ ticket }) {

    const formatearFecha = (fechaISO) => {
        const fecha = new Date(fechaISO);
        const dia = fecha.getDate().toString().padStart(2, '0');
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const anio = fecha.getFullYear();
        return `${dia}/${mes}/${anio}`;
    };

    const qrValue = ticket?.identifier;
    const [qrImage, setQrImage] = React.useState(null);

    React.useEffect(() => {
        if (qrValue) {
            QRCode.toDataURL(qrValue)
                .then((url) => {
                    setQrImage(url);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [qrValue]);

    let styles = StyleSheet.create({
        pagina: {
            flexDirection: 'row',
            padding: 10,
        },
        fondoVertical: {
            width: 400,
            height: 355,
            position: 'absolute',
            zIndex: 0,
        },
        fondoHorizontal: {
            width: 355,
            height: 400,
            position: 'absolute',
            zIndex: 0,
        },
        contenedorFondo: {
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
        },
        imagenFondo: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        escudoVertical: {
            width: 100,
            height: 100,
            overflow: 'hidden',
            position: 'absolute',
            left: 40,
            top: 320,
        },
        escudo2Vertical: {
            width: 100,
            height: 100,
            overflow: 'hidden',
            position: 'absolute',
            left: 265,
            top: 320,
        },
        contenedorEscudo: {
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        imagenEscudo: {
            height: 'auto',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%',
        },
        logo: {
            height: 'auto',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%',
        },
        qr: {
            height: 'auto',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%',
        },
    });

    const orientation = ticket?.match?.ticketOrientation;
    const widthTicket = ticket?.match?.ticketOrientation ? 670 : 420;
    const heightTicket = ticket?.match?.ticketOrientation ? 420 : 670;
    const template = ticket?.match?.ticketOrientation ? '/images/templateTicketHorizontal.png' : '/images/templateTicketVertical.png';

    const matchTemplate = ticket?.match?.template ? `${ticket?.match?.template}?v=1` : undefined;
    const hostBadge = ticket?.match?.hostBadge ? `${ticket?.match?.hostBadge}?v=1` : undefined;
    const visitorBadge = ticket?.match?.visitorBadge ? `${ticket?.match?.visitorBadge}?v=1` : undefined;
    const thumbnail = ticket?.match?.thumbnail ? `${ticket?.match?.thumbnail}?v=1` : undefined;

    return (
        <Document>
            <Page size={{ width: widthTicket, height: heightTicket }} style={styles.pagina}>
                <View>
                    <View style={orientation ? styles.fondoHorizontal : styles.fondoVertical}>
                        {matchTemplate && (
                            <View style={styles.contenedorFondo}>
                                <Image style={styles.imagenFondo} src={matchTemplate} />
                            </View>
                        )}
                    </View>
                    <Image style={orientation ? styles.templateHorizontal : styles.templateVertical} src={template} />
                    {hostBadge && (
                        <View style={orientation ? styles.escudoHorizontal : styles.escudoVertical}>
                            <View style={styles.contenedorEscudo}>
                                <Image style={styles.imagenEscudo} src={hostBadge} />
                            </View>
                        </View>
                    )}
                    {visitorBadge && (
                        <View style={orientation ? styles.escudo2Horizontal : styles.escudo2Vertical}>
                            <View style={styles.contenedorEscudo}>
                                <Image style={styles.imagenEscudo} src={visitorBadge} />
                            </View>
                        </View>
                    )}
                    {thumbnail && (
                        <View style={styles.contenedorLogo}>
                            <Image style={styles.logo} src={thumbnail} />
                        </View>
                    )}
                    {qrImage && (
                        <View style={styles.contenedorQR}>
                            <Image style={styles.qr} src={qrImage} />
                        </View>
                    )}
                </View>
            </Page>
        </Document>
    );
}
